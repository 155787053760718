import React from "react";
import Bar from "./Bar";
import LoginUser from "./LoginUser";
import classes from "./Login.module.css";

function Login() {
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Bar />
        <LoginUser />
      </div>
      <div className={classes.background_image}></div>
    </React.Fragment>
  );
}

export default Login;