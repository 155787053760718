// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/svg/support-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Support_support_container__X4ycS {
    display: inline-flex;
    flex-direction: column;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-color: rgba(0, 163, 173, 1);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 15px;
}

.Support_support_container__X4ycS > :nth-child(1) {
    margin-bottom: 15px;
}

.Support_support_container__X4ycS > :nth-child(3) {
    margin-bottom: 15px;
}

.Support_support_container__X4ycS > :nth-child(4) {
    margin-bottom: 10px;
}

.Support_support_image__8\\+Fnx {
    width: 30px;
    height: 30px;  
}

.Support_support_label__nI3uw {
    font-weight: 100;
    font-size: 10px;

}`, "",{"version":3,"sources":["webpack://./src/components/home/left-panel/Support.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,yDAA2D;IAC3D,sCAAsC;IACtC,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;;AAEnB","sourcesContent":[".support_container {\n    display: inline-flex;\n    flex-direction: column;\n    background-image: url('../../../assets/svg/support-bg.svg');\n    background-color: rgba(0, 163, 173, 1);\n    color: white;\n    border: none;\n    border-radius: 10px;\n    padding: 10px 20px;\n    font-size: 15px;\n}\n\n.support_container > :nth-child(1) {\n    margin-bottom: 15px;\n}\n\n.support_container > :nth-child(3) {\n    margin-bottom: 15px;\n}\n\n.support_container > :nth-child(4) {\n    margin-bottom: 10px;\n}\n\n.support_image {\n    width: 30px;\n    height: 30px;  \n}\n\n.support_label {\n    font-weight: 100;\n    font-size: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support_container": `Support_support_container__X4ycS`,
	"support_image": `Support_support_image__8+Fnx`,
	"support_label": `Support_support_label__nI3uw`
};
export default ___CSS_LOADER_EXPORT___;
