// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonGroup_container__ZXiCA > button {
  color: #c8c8c8;
  font-size: 0.7rem;
  background: #f4f4f4;
  border: none;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-transform: uppercase;
  width: 5rem;
}

.ButtonGroup_left_btn__SExDe {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.ButtonGroup_right_btn__gmVJp {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/ButtonGroup.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,sCAAsC;EACtC,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,8BAA8B;EAC9B,iCAAiC;AACnC;;AAEA;EACE,+BAA+B;EAC/B,kCAAkC;AACpC","sourcesContent":[".container > button {\n  color: #c8c8c8;\n  font-size: 0.7rem;\n  background: #f4f4f4;\n  border: none;\n  padding: 0.3rem;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  text-align: center;\n  text-transform: uppercase;\n  width: 5rem;\n}\n\n.left_btn {\n  border-top-left-radius: 0.5rem;\n  border-bottom-left-radius: 0.5rem;\n}\n\n.right_btn {\n  border-top-right-radius: 0.5rem;\n  border-bottom-right-radius: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ButtonGroup_container__ZXiCA`,
	"left_btn": `ButtonGroup_left_btn__SExDe`,
	"right_btn": `ButtonGroup_right_btn__gmVJp`
};
export default ___CSS_LOADER_EXPORT___;
