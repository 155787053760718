import React, { useState } from 'react';
import './Steps.css';
import StepsPanel from './StepsPanel';
import StepsGraphPanel from './StepsGraphPanel';

const Steps = () => {

  const [selectedDiv, setSelectedDiv] = useState(1);

  const handleClick = (divId) => {
    setSelectedDiv(divId);
  };

  const marks = {
    0: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week4</div>
        </div>
      )
    },
    1: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },
    2: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week5</div>
        </div>
      )
    },
    3: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    4: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week6</div>
        </div>
      )
    },
    5: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },
    6: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week7</div>
        </div>
      )
    },
    7: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    8: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week8</div>
        </div>
      )
    },
    9: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    10: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>NOW</div>
        </div>
      ) 
    }
  }


  return (
    <div style={{ display: 'flex' }}>
      <StepsPanel
        title="Status"
        marks={marks}
        className={`selectable-div ${selectedDiv === 1 ? 'selected' : ''}`}
        onClick={() => handleClick(1)}
      />
      <StepsGraphPanel
        className={`selectable-div ${selectedDiv === 2 ? 'selected' : ''}`}
        onClick={() => handleClick(2)}
      />
    </div>
  );

};

export default Steps;