import React, { useState, useCallback, useEffect, useContext } from "react";
import classes from "./TemperaturePanel.module.css";
import ButtonGroup from "../ButtonGroup";
import { ReactComponent as TemperatureSvg } from '../../../../../assets/svg/feet-temperature.svg';
import TimerSlider from "../TimerSlider";
import AreaListView from "../AreaListView";
import axios from "axios";
import { getPastDate } from "../../../../../utils/utils";
import { AuthContext } from '../../../../../AuthContext';

const TemperaturePanel = ({ title, marks, className, onClick, onSelectArea }) => {
  const [temperatureData, setTemperatureData] = useState([]);
  const [selectedArea, setSelectedArea] = useState("All");

  const { authData } = useContext(AuthContext);

  const URL = process.env.REACT_APP_API_BASE_URL + "/v1/fetch/shoe/" + authData.id + "/temperature?period=P1W";
  const TOKEN = process.env.REACT_APP_API_TOKEN;


  const updateElements = useCallback((data, area) => {
    const updateSvgElement = (key, value, color, textColor) => {
      const textEl = document.getElementById(key);
      const innerCircleEl = document.getElementById(key.replace("_text", "_circle_inner"));
      const outerCircleEl = document.getElementById(key.replace("_text", "_circle_outer"));
      const exclam = document.getElementById(key.replace("_text", "_exclam"));
      const exclamInnerWhiteCircle = document.getElementById(key.replace("_text", "_exclam_inner_white_circle"));

      if (textEl) { 
        textEl.textContent = `${value.toFixed(1)}°`;
        textEl.setAttribute("fill", textColor);
        textEl.setAttribute("stroke", textColor);
      }


      if (innerCircleEl) {
        const setVisibility = (visibility) => {
          exclam.setAttribute("visibility", visibility);
          exclamInnerWhiteCircle.setAttribute("visibility", visibility);
        };
      
        const attributes = {
          "#FF675C": { fill: color, stroke: "#FFBFBA", visibility: "visible" },
          "#DAF84D": { fill: color, stroke: "#F1FFAF", visibility: "hidden" },
          "#A9E66C": { fill: color, stroke: "#D4F8B0", visibility: "hidden" },
          default: { fill: "#C6CACB", stroke: "#D8DCDD" },
        };
      
        const { fill, stroke, visibility } = attributes[color] || attributes.default;
      
        if (fill === "#C6CACB" && exclam.getAttribute("visibility") === "visible") {
          exclam.setAttribute("fill", "#757879");
        }
        else {
          exclam.setAttribute("fill", "#FF675C");
        }        
        
        innerCircleEl.setAttribute("fill", fill);
        innerCircleEl.setAttribute("stroke", stroke);
        
        if (visibility !== undefined) {
          setVisibility(visibility);
        } else {
          setVisibility(exclam.getAttribute("visibility") === "visible" ? "visible" : "hidden");
        }
      }
      
      [outerCircleEl].forEach(el => {
        if(el) {
          switch (color) {
            case "#FF675C":
              el.setAttribute("fill", "#FFBFBA");
            break;
            case "#DAF84D":
              el.setAttribute("fill", "#F1FFAF");
            break;
            case "#A9E66C":
              el.setAttribute("fill", "#D4F8B0");
            break;
            default:
              el.setAttribute("fill", color);
          }
        }
      });

    };

    Object.entries(data).forEach(([key, value]) => {
      const areaKey = key.replace("_text", "");
      let color;
      let textColor;
      if(area === "All" || area === areaKey) {
        color = getTemperatureColor(Math.floor(value));
        textColor = "#4D4D4D";
      }
      else {
        color = "#F5F5F5";
        textColor = "#A1A3A4"
      }
      updateSvgElement(key, value, color, textColor);
    });
  }, []);

  const styleTemperatureAreas = useCallback(area => {
    if (Object.keys(temperatureData).length > 0) {
      updateElements(temperatureData.left_data, area);
      updateElements(temperatureData.right_data, area);
    }
  }, [temperatureData, updateElements]);

  const fetchTemperatureData = useCallback(async (dateTime) => {
    try {
      const response = await axios.get(`${URL}&time=${dateTime}`, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      const renamedData = renameSensors(response.data);
      setTemperatureData(renamedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [TOKEN, URL]);

  useEffect(() => {
    fetchTemperatureData(getPastDate(0));
  }, [fetchTemperatureData]);

  useEffect(() => {
    styleTemperatureAreas(selectedArea);
    onSelectArea(selectedArea); // Notify parent component
  }, [temperatureData, selectedArea, styleTemperatureAreas, onSelectArea]);

  const renameSensors = (data) => {
    const renameKeys = (data, newNames) => Object.entries(data).reduce((acc, [key, value]) => {
      const index = key.split("_")[1];
      acc[newNames[index]] = value;
      return acc;
    }, {});

    const leftNames = [
        "L1_text", "L2_text", "L3_text", 
        "L4_text", "L5_text", "L6_text", 
        "L7_text", "L8_text", "L9_text"
    ];
    
    const rightNames = [
        "R1_text", "R2_text", "R3_text", 
        "R4_text", "R5_text", "R6_text", 
        "R7_text", "R8_text", "R9_text"
    ];
    
    return {
      ...data,
      left_data: renameKeys(data.left_data, leftNames),
      right_data: renameKeys(data.right_data, rightNames),
    };
  };

  const getTemperatureColor = (value) => {
    if (value >= 58 && value <= 71) return "#A9E66C";
    if (value >= 72 && value <= 86) return "#DAF84D";
    if (value >= 87 && value <= 100) return "#FF675C";
    return "#F5F5F5";
  };

  const onSlideChangeHandler = (value) => {
    const daysAgo = 35 - value * 3.5;
    fetchTemperatureData(getPastDate(daysAgo));
  };

  return (
    <div className={`${classes.container} ${className}`} onClick={onClick}>
      <div className={classes.edge_items}>
        <label className={classes.label_1}>{title}</label>
        <ButtonGroup />
      </div>
      <AreaListView title="Feet area" options={["All", "R1", "R2", "R3", "R4", "R5", "R6", "R7", "R8", "R9", "L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9"]} onListViewOptionSelect={setSelectedArea} />
      <TemperatureSvg className={classes.svg} />
      <div className={classes.edge_items}>
        <label className={classes.side_labels}>Left</label>
        <label className={classes.side_labels}>Right</label>
      </div>
      <div className={classes.week_slider}>
        <TimerSlider marks={marks} onSlideChange={onSlideChangeHandler} />
      </div>
    </div>
  );
};

export default TemperaturePanel;