// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PanelItem_panel_item__P0pu- {
    display: flex;
    align-items: center;
    margin-left: 7%;
    cursor: pointer;
}

.PanelItem_panel_item_image__NsFKK {
    width: 40px;
    height: 40px;
    margin-right: 5%;
}

.PanelItem_panel_item_label__ooyik {
    color: #A0AEC0;
    margin-left: 5px;

}`, "",{"version":3,"sources":["webpack://./src/components/home/left-panel/PanelItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;;AAEpB","sourcesContent":[".panel_item {\n    display: flex;\n    align-items: center;\n    margin-left: 7%;\n    cursor: pointer;\n}\n\n.panel_item_image {\n    width: 40px;\n    height: 40px;\n    margin-right: 5%;\n}\n\n.panel_item_label {\n    color: #A0AEC0;\n    margin-left: 5px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel_item": `PanelItem_panel_item__P0pu-`,
	"panel_item_image": `PanelItem_panel_item_image__NsFKK`,
	"panel_item_label": `PanelItem_panel_item_label__ooyik`
};
export default ___CSS_LOADER_EXPORT___;
