// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DoctorsListView_listview_container__qWXB7 {
  position: relative;
}

.DoctorsListView_listview_container__qWXB7:hover {
  cursor: pointer;
}


.DoctorsListView_paper__OIIPv {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/home/header/DoctorsListView.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;;AAGA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,UAAU;AACZ","sourcesContent":[".listview_container {\n  position: relative;\n}\n\n.listview_container:hover {\n  cursor: pointer;\n}\n\n\n.paper {\n  position: absolute;\n  left: 0;\n  right: 0;\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listview_container": `DoctorsListView_listview_container__qWXB7`,
	"paper": `DoctorsListView_paper__OIIPv`
};
export default ___CSS_LOADER_EXPORT___;
