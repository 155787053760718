// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base style for all divs */
.selectable-div {
    margin-top: 7%;
    margin-right: 2%;
    transition: transform 0.3s, z-index 0.3s;
    cursor: pointer;
  }
  
  /* Style for the selected div */
  .selected {
    transform: scale(1.1);
    border: 2px solid #CDEDEE;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/skin-hydration/SkinHydration.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,cAAc;IACd,gBAAgB;IAChB,wCAAwC;IACxC,eAAe;EACjB;;EAEA,+BAA+B;EAC/B;IACE,qBAAqB;IACrB,yBAAyB;EAC3B","sourcesContent":["/* Base style for all divs */\n.selectable-div {\n    margin-top: 7%;\n    margin-right: 2%;\n    transition: transform 0.3s, z-index 0.3s;\n    cursor: pointer;\n  }\n  \n  /* Style for the selected div */\n  .selected {\n    transform: scale(1.1);\n    border: 2px solid #CDEDEE;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
