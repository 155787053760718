// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/svg/login-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_background_image__TVDDa {
    border-radius: 0 0 0 15px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover; /* Cover the entire viewport */
    background-repeat: no-repeat; /* Prevent background repetition */
    position: fixed; /* Fix the background to the viewport */
    width: 800px;
    height: 800px;
    z-index: -1; /* Ensure the background stays behind other content */
    left: 80%;
    transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/login/Login.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yDAAsD;IACtD,sBAAsB,EAAE,8BAA8B;IACtD,4BAA4B,EAAE,kCAAkC;IAChE,eAAe,EAAE,uCAAuC;IACxD,YAAY;IACZ,aAAa;IACb,WAAW,EAAE,qDAAqD;IAClE,SAAS;IACT,2BAA2B;AAC/B","sourcesContent":[".background_image {\n    border-radius: 0 0 0 15px;\n    background-image: url('../../assets/svg/login-bg.svg');\n    background-size: cover; /* Cover the entire viewport */\n    background-repeat: no-repeat; /* Prevent background repetition */\n    position: fixed; /* Fix the background to the viewport */\n    width: 800px;\n    height: 800px;\n    z-index: -1; /* Ensure the background stays behind other content */\n    left: 80%;\n    transform: translateX(-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background_image": `Login_background_image__TVDDa`
};
export default ___CSS_LOADER_EXPORT___;
