// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Panel_panel__rcFui {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 15px;
    
}

.Panel_panel__rcFui > :nth-child(1) {
    margin-bottom: 20px;
}

.Panel_panel__rcFui > :nth-child(7) {
    margin-bottom: 85%;
}

.Panel_logo__gFKAy {
    height: 90%;
    width: 90%;
    margin-top: 20px;
}

.Panel_label__l96bO {
    color: #2D3748;
    margin-left: 10%;   
    font-size: small;
    font-weight: 1000; 
}`, "",{"version":3,"sources":["webpack://./src/components/home/left-panel/Panel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,SAAS;;AAEb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".panel {\n    display: flex;\n    flex-direction: column;\n    margin: 0 auto;\n    gap: 15px;\n    \n}\n\n.panel > :nth-child(1) {\n    margin-bottom: 20px;\n}\n\n.panel > :nth-child(7) {\n    margin-bottom: 85%;\n}\n\n.logo {\n    height: 90%;\n    width: 90%;\n    margin-top: 20px;\n}\n\n.label {\n    color: #2D3748;\n    margin-left: 10%;   \n    font-size: small;\n    font-weight: 1000; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `Panel_panel__rcFui`,
	"logo": `Panel_logo__gFKAy`,
	"label": `Panel_label__l96bO`
};
export default ___CSS_LOADER_EXPORT___;
