// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkinHydrationPanel_container__qCiM6 {
    background-color: white;
    width: 29rem;
    height: 85%;
    padding: 1rem;
    border-radius: 3%;
}

.SkinHydrationPanel_edge_items__E0bpm {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SkinHydrationPanel_label_1__7-4EB {
    font-weight: 550;
    font-size: 20px;
}

.SkinHydrationPanel_svg_container__vJT7y {
    display: flex; /* Flexbox to place elements side by side */
    justify-content: center; /* Center the items */
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
    margin-top: 2.5rem;
}

.SkinHydrationPanel_svg_item__muqTG {
    width: 27.2%; /* Each SVG takes 50% of the container */
    display: flex;
    justify-content: center;
    margin: 0 10px; /* Adjust margin to bring the items closer */
}

.SkinHydrationPanel_svg__wCMtO {
    width: 100%; /* The SVG will take the full width of its container */
    height: auto; /* Maintain aspect ratio */
}

.SkinHydrationPanel_svg__wCMtO:hover {
    cursor: pointer;
}


.SkinHydrationPanel_side_labels__yYrZ8 {
    color: #00A3AD;
    font-weight: 600;
    font-size: 12px;
}

.SkinHydrationPanel_week_slider__UXjLT {
    width: 80%; 
    margin: 30px auto
}

.SkinHydrationPanel_listview__UVRKZ {
    visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/skin-hydration/SkinHydrationPanel.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa,EAAE,2CAA2C;IAC1D,uBAAuB,EAAE,qBAAqB;IAC9C,mBAAmB;IACnB,WAAW,EAAE,0CAA0C;IACvD,kBAAkB;AACtB;;AAEA;IACI,YAAY,EAAE,wCAAwC;IACtD,aAAa;IACb,uBAAuB;IACvB,cAAc,EAAE,4CAA4C;AAChE;;AAEA;IACI,WAAW,EAAE,sDAAsD;IACnE,YAAY,EAAE,0BAA0B;AAC5C;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV;AACJ;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    background-color: white;\n    width: 29rem;\n    height: 85%;\n    padding: 1rem;\n    border-radius: 3%;\n}\n\n.edge_items {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.label_1 {\n    font-weight: 550;\n    font-size: 20px;\n}\n\n.svg_container {\n    display: flex; /* Flexbox to place elements side by side */\n    justify-content: center; /* Center the items */\n    align-items: center;\n    width: 100%; /* Ensure the container takes full width */\n    margin-top: 2.5rem;\n}\n\n.svg_item {\n    width: 27.2%; /* Each SVG takes 50% of the container */\n    display: flex;\n    justify-content: center;\n    margin: 0 10px; /* Adjust margin to bring the items closer */\n}\n\n.svg {\n    width: 100%; /* The SVG will take the full width of its container */\n    height: auto; /* Maintain aspect ratio */\n}\n\n.svg:hover {\n    cursor: pointer;\n}\n\n\n.side_labels {\n    color: #00A3AD;\n    font-weight: 600;\n    font-size: 12px;\n}\n\n.week_slider {\n    width: 80%; \n    margin: 30px auto\n}\n\n.listview {\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SkinHydrationPanel_container__qCiM6`,
	"edge_items": `SkinHydrationPanel_edge_items__E0bpm`,
	"label_1": `SkinHydrationPanel_label_1__7-4EB`,
	"svg_container": `SkinHydrationPanel_svg_container__vJT7y`,
	"svg_item": `SkinHydrationPanel_svg_item__muqTG`,
	"svg": `SkinHydrationPanel_svg__wCMtO`,
	"side_labels": `SkinHydrationPanel_side_labels__yYrZ8`,
	"week_slider": `SkinHydrationPanel_week_slider__UXjLT`,
	"listview": `SkinHydrationPanel_listview__UVRKZ`
};
export default ___CSS_LOADER_EXPORT___;
