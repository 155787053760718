import React, { useState, useEffect, useCallback, useContext } from "react";
import classes from "./PressurePanel.module.css";
import ButtonGroup from "../ButtonGroup";
import { ReactComponent as PressureSvg } from '../../../../../assets/svg/feet-pressure.svg';
import TimerSlider from "../TimerSlider";
import AreaListView from "../AreaListView";
import axios from "axios";
import { getPastDate } from "../../../../../utils/utils";
import { AuthContext } from '../../../../../AuthContext';

const PressurePanel = ({ title, marks, className, onClick, onSelectArea }) => {  // Add className and onClick as props
    const [pressureData, setPressureData] = useState([]);
    const [selectedArea, setSelectedArea] = useState("All");

    const { authData } = useContext(AuthContext);

    const URL = process.env.REACT_APP_API_BASE_URL + "/v1/fetch/shoe/" + authData.id + "/pressure?period=P1W";
    const TOKEN = process.env.REACT_APP_API_TOKEN;

    // Update the attributes of the SVG elements
    const updateAttributes = (el, fill, stroke, strokeWidth, strokeOpacity) => {
        if (el) {
            el.setAttribute("fill", fill);
            el.setAttribute("stroke", stroke);
            if (strokeWidth) el.setAttribute("stroke-width", strokeWidth);
            if (strokeOpacity) el.setAttribute("stroke-opacity", strokeOpacity);
        }
    };


    // Memoize the updateElements function using useCallback
    const updateElements = useCallback((data, area, isSelected) => {
        for (const [key, value] of Object.entries(data)) {
            const el = document.getElementById(key);
            if (area === "All" || area === key) {
                updateAttributes(el, getPressureColor(value), isSelected ? "#DEFFFF" : "none", isSelected ? "2.75359" : null, isSelected ? "none" : null);
            } else {
                updateAttributes(el, "#F5F5F5", "#818181", "0.917865", "0.6");
            }
        }
    }, []);

    // Memoize the stylePressureAreas function and include updateElements as a dependency
    const stylePressureAreas = useCallback((area) => {
        if (pressureData && Object.keys(pressureData).length > 0) {
            updateElements(pressureData.left_data, area, area !== "All");
            updateElements(pressureData.right_data, area, area !== "All");
        }
    }, [pressureData, updateElements]);

    // Fetch pressure data from the API
    const fetchPressureData = useCallback(async (dateTime) => {
        try {
            const response = await axios.get(`${URL}&time=${dateTime}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            const data = response.data;

            const leftNames = [
                "L1.1", "L1.2", "L1.3", "L1.4",
                "L2.1", "L2.2", "L2.3", "L2.4",
                "L3.1", "L3.2", "L3.3",
                "L4.1", "L4.2", "L4.3",
                "L5.1", "L5.2",
                "L6.1", "L6.2"
            ];

            const rightNames = [
                "R1.1", "R1.2", "R1.3", "R1.4",
                "R2.1", "R2.2", "R2.3", "R2.4",
                "R3.1", "R3.2", "R3.3",
                "R4.1", "R4.2", "R4.3",
                "R5.1", "R5.2",
                "R6.1", "R6.2"
            ];

            const renamedData = renameSensors(data, leftNames, rightNames);
            setPressureData(renamedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [TOKEN, URL]);

    // Fetch data only once when the component mounts
    useEffect(() => {
        fetchPressureData(getPastDate(0));
    }, [fetchPressureData]);

    // Update the pressure areas when data or the selected area changes
    useEffect(() => {
        stylePressureAreas(selectedArea);
        onSelectArea(selectedArea); // Notify parent component
    }, [pressureData, selectedArea, stylePressureAreas, onSelectArea]);

    // Function to rename sensors in the data
    const renameSensors = (data, leftNames, rightNames) => {
        const renameKeys = (data, newNames) => {
            return Object.entries(data).reduce((acc, [key, value]) => {
                if (key.startsWith("sensor_")) {
                    const index = key.split("_")[1];
                    const newName = newNames[index];
                    acc[newName] = value;
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {});
        };

        return {
            ...data,
            left_data: renameKeys(data.left_data, leftNames),
            right_data: renameKeys(data.right_data, rightNames),
        };
    };

    // Handle area selection from the list view
    const onListViewOptionSelectHandler = (option) => {
        setSelectedArea(option);
    };

    // Get the appropriate color based on pressure data
    const getPressureColor = (data) => {
        const value = Number(data);
        if (value <= 500) return "#7FB6FB";
        if (value <= 1000) return "#8BD4FF";
        if (value <= 1500) return "#89EDD5";
        if (value <= 2000) return "#B8EE92";
        if (value <= 2500) return "#E7ED78";
        if (value <= 3000) return "#F7EE7B";
        if (value <= 3500) return "#FFC87B";
        if (value <= 4000) return "#FFAA8A";
        return "#F5F5F5";
    };

    // Options for the area list
    const options = [
        "All", "R1.1", "R1.2", "R1.3", "R1.4",
        "R2.1", "R2.2", "R2.3", "R2.4",
        "R3.1", "R3.2", "R3.3",
        "R4.1", "R4.2", "R4.3",
        "R5.1", "R5.2",
        "R6.1", "R6.2",
        "L1.1", "L1.2", "L1.3", "L1.4",
        "L2.1", "L2.2", "L2.3", "L2.4",
        "L3.1", "L3.2", "L3.3",
        "L4.1", "L4.2", "L4.3",
        "L5.1", "L5.2",
        "L6.1", "L6.2"
    ];

    const onSlideChangeHandler = (value) => {
  
        switch(value) {
          case 0:
            fetchPressureData(getPastDate(35)); break;
          case 1:
            fetchPressureData(getPastDate(31.5)); break;
          case 2:
            fetchPressureData(getPastDate(28)); break;
          case 3:
            fetchPressureData(getPastDate(24.5)); break;
          case 4:
            fetchPressureData(getPastDate(21)); break;
          case 5:
            fetchPressureData(getPastDate(17.5)); break;
          case 6:
            fetchPressureData(getPastDate(14)); break;
          case 7:
            fetchPressureData(getPastDate(10.5)); break;
          case 8:
            fetchPressureData(getPastDate(7)); break;
          case 9:
            fetchPressureData(getPastDate(3.5)); break;
          default :
          fetchPressureData(getPastDate(0));
        }
      };

    return (
        <React.Fragment>
            <div className={`${classes.container} ${className}`} onClick={onClick}>
                <div className={classes.edge_items}>
                    <label className={classes.label_1}>{title}</label>
                    <ButtonGroup />
                </div>
                <div>
                    <AreaListView title="Pressure area" options={options} onListViewOptionSelect={onListViewOptionSelectHandler} />
                </div>
                <div>
                    <PressureSvg className={classes.svg} />
                </div>
                <div className={classes.edge_items}>
                    <label className={classes.side_labels}>Left</label>
                    <label className={classes.side_labels}>Right</label>
                </div>
                <div className={classes.week_slider}>
                    <TimerSlider marks={marks} onSlideChange={onSlideChangeHandler} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default PressurePanel;