import React, { useEffect } from 'react';
import classes from "./SkinHydrationGraphPanel.module.css";

import { ReactComponent as HydrationGraphSvg } from '../../../../../assets/mocks/hydration-graph.svg';


const SkinHydrationGraphPanel = ({className, onClick, selectedHydrationArea}) => {

    const updateSvgElement = (area) => {
        document.getElementById("hydration_graph_area").textContent = area;
    }

    useEffect(() => {
        if(selectedHydrationArea) {
            updateSvgElement(selectedHydrationArea);
        }
    });

    return (
        <div className={`${classes.container} ${className}`} onClick={onClick}>
            <HydrationGraphSvg className={classes.svg} />
      </div>
  
    )

}

export default SkinHydrationGraphPanel;