// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepsPanel_container__ZZtuM {
    background-color: white;
    width: 29rem;
    height: 85%;
    padding: 1.0rem;
    border-radius: 3%;
}

.StepsPanel_edge_items__Nj4CG {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.StepsPanel_label_1__WEd35 {
    font-weight: 550;
    font-size: 20px;
}

.StepsPanel_svg__ByxnN {
    width: 60%;
    height: 60%;
    display: block;
    margin: auto;
    margin-top: 2.5rem;
}

.StepsPanel_side_labels__sUuL9 {
    color: #00A3AD;
    font-weight: 600;
    font-size: 12px;
}

.StepsPanel_week_slider__wrzLM {
    width: 80%; 
    margin: 30px auto
}

.StepsPanel_step_count__7yFFE {
    text-align: center;
    margin-top: 1%;
    font-size: 2.5em;
    font-weight: bold;
}

.StepsPanel_steps_label__yTHL8 {
    text-align: center;
    margin-top: 1%;
    font-size: 20px;
    font-weight: 600;
  }`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/steps/StepsPanel.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV;AACJ;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".container {\n    background-color: white;\n    width: 29rem;\n    height: 85%;\n    padding: 1.0rem;\n    border-radius: 3%;\n}\n\n.edge_items {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.label_1 {\n    font-weight: 550;\n    font-size: 20px;\n}\n\n.svg {\n    width: 60%;\n    height: 60%;\n    display: block;\n    margin: auto;\n    margin-top: 2.5rem;\n}\n\n.side_labels {\n    color: #00A3AD;\n    font-weight: 600;\n    font-size: 12px;\n}\n\n.week_slider {\n    width: 80%; \n    margin: 30px auto\n}\n\n.step_count {\n    text-align: center;\n    margin-top: 1%;\n    font-size: 2.5em;\n    font-weight: bold;\n}\n\n.steps_label {\n    text-align: center;\n    margin-top: 1%;\n    font-size: 20px;\n    font-weight: 600;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StepsPanel_container__ZZtuM`,
	"edge_items": `StepsPanel_edge_items__Nj4CG`,
	"label_1": `StepsPanel_label_1__WEd35`,
	"svg": `StepsPanel_svg__ByxnN`,
	"side_labels": `StepsPanel_side_labels__sUuL9`,
	"week_slider": `StepsPanel_week_slider__wrzLM`,
	"step_count": `StepsPanel_step_count__7yFFE`,
	"steps_label": `StepsPanel_steps_label__yTHL8`
};
export default ___CSS_LOADER_EXPORT___;
