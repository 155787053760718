// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeDashboard_home_title__daqFL {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 163, 173, 1);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .HomeDashboard_home_title__daqFL:hover {
    background-color: rgba(0, 140, 150, 1);
  }
  
  .HomeDashboard_title_icon__x4NZO {
    margin-right: 10px;
    width: 30px;
    height: 30px;  }
  
  .HomeDashboard_title_text__MrobF {
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/components/home/left-panel/HomeDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,sCAAsC;IACtC,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY,GAAG;;EAEjB;IACE,iBAAiB;EACnB","sourcesContent":[".home_title {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 163, 173, 1);\n    color: white;\n    border: none;\n    border-radius: 10px;\n    padding: 10px 20px;\n    font-size: 14px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .home_title:hover {\n    background-color: rgba(0, 140, 150, 1);\n  }\n  \n  .title_icon {\n    margin-right: 10px;\n    width: 30px;\n    height: 30px;  }\n  \n  .title_text {\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home_title": `HomeDashboard_home_title__daqFL`,
	"title_icon": `HomeDashboard_title_icon__x4NZO`,
	"title_text": `HomeDashboard_title_text__MrobF`
};
export default ___CSS_LOADER_EXPORT___;
