import React, { useState } from 'react';
import "./Temperature.css";
import TemperaturePanel from "./TemperaturePanel";
import TemperatureGraphPanel from "./TemperatureGraphPanel";

const Temperature = () => {

  const [selectedDiv, setSelectedDiv] = useState(1);
  const [selectedArea, setSelectedArea] = useState("All");

  const handleClick = (divId) => {
    setSelectedDiv(divId);
  };

  const handleSelectedArea = (selectedArea) => {
    setSelectedArea(selectedArea);
  }

  /*
  // Hourly slider data
  const marks={0:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>10H</div></div>)},1:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>9H</div></div>)},2:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>8H</div></div>)},3:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>7H</div></div>)},4:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>6H</div></div>)},5:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>5H</div></div>)},6:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>4H</div></div>)},7:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>3H</div></div>)},8:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>2H</div></div>)},9:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>1H</div></div>)},10:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>NOW</div></div>)}};
  */

  const marks={0:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>Week4</div></div>)},1:{style:{fontSize:'10px',color:'#c8c8c8'},label:'|'},2:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>Week5</div></div>)},3:{style:{fontSize:'10px',color:'#c8c8c8'},label:'|'},4:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>Week6</div></div>)},5:{style:{fontSize:'10px',color:'#c8c8c8'},label:'|'},6:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>Week7</div></div>)},7:{style:{fontSize:'10px',color:'#c8c8c8'},label:'|'},8:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>Week8</div></div>)},9:{style:{fontSize:'10px',color:'#c8c8c8'},label:'|'},10:{style:{fontSize:'10px',color:'#c8c8c8'},label:(<div><div>|</div><div>NOW</div></div>)}}

  return (
    <div style={{ display: 'flex' }}>
      <TemperaturePanel
        title="Status"
        marks={marks}
        className={`selectable-div ${selectedDiv === 1 ? 'selected' : ''}`}
        onClick={() => handleClick(1)}
        onSelectArea={handleSelectedArea}
      />
      <TemperatureGraphPanel
        className={`selectable-div ${selectedDiv === 2 ? 'selected' : ''}`}
        onClick={() => handleClick(2)}
        selectedTemperatureArea={selectedArea}
      />
    </div>
  );
};

export default Temperature;