import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Home from './components/home/Home';
import Login from './components/login/Login';
import HealthCheck from './components/HealthCheck';
import { AuthContext } from './AuthContext'; // Import the AuthContext

const App = () => {
  const { authData, logout } = useContext(AuthContext); // Get auth data and logout function from context

  return (
    <Router>
      <div>
        <Routes>
          {/* Health route accessible to anyone */}
          <Route path="/health" element={<HealthCheck />} />
          
          {/* Authentication logic */}
          {authData.isAuthenticated ? (
            <React.Fragment>
              {/* Home component contains Navbar and the routes */}
              <Route path="/*" element={<Home onLogout={logout} />} />
              <Route path="*" element={<Navigate to="/overview" />} /> {/* Redirect to overview by default */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to Login if not authenticated */}
            </React.Fragment>
          )}
        </Routes>
      </div>
    </Router>
  );
};

export default App;