import React from "react";
import classes from "../Pages.module.css";

const Overview = () => {
  return (
    <div className={classes.page}>
      <img alt="Overview" src={require("../../../../../assets/mocks/overview.png")} />    
    </div>
  );
};

export default Overview;
