// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleSwitch_toggle_switch__mNC1N {
    display: flex;
    align-items: center;
  }
  
  .ToggleSwitch_switch_input__8BDYI {
    display: none;
  }
  
  .ToggleSwitch_switch_label__lz1t\\+ {
    position: relative;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .ToggleSwitch_switch_label__lz1t\\+::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
  }
  
  /* Change the background color and move the toggle when checked */
  .ToggleSwitch_switch_input__8BDYI:checked + .ToggleSwitch_switch_label__lz1t\\+ {
    background-color: #00A3AD;
  }
  
  .ToggleSwitch_switch_input__8BDYI:checked + .ToggleSwitch_switch_label__lz1t\\+::before {
    transform: translateX(20px);
  }
  
  .ToggleSwitch_switch_label_text__3KKuq {
    margin-left: 8px;
    font-size: 14px;
    color: #333;
  }  `, "",{"version":3,"sources":["webpack://./src/components/login/ToggleSwitch.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,6CAA6C;EAC/C;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA,iEAAiE;EACjE;IACE,yBAAyB;EAC3B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb","sourcesContent":[".toggle_switch {\n    display: flex;\n    align-items: center;\n  }\n  \n  .switch_input {\n    display: none;\n  }\n  \n  .switch_label {\n    position: relative;\n    width: 40px;\n    height: 20px;\n    background-color: #ccc;\n    border-radius: 20px;\n    cursor: pointer;\n    transition: background-color 0.2s ease-in-out;\n  }\n  \n  .switch_label::before {\n    content: '';\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    width: 16px;\n    height: 16px;\n    background-color: white;\n    border-radius: 50%;\n    transition: transform 0.2s ease-in-out;\n  }\n  \n  /* Change the background color and move the toggle when checked */\n  .switch_input:checked + .switch_label {\n    background-color: #00A3AD;\n  }\n  \n  .switch_input:checked + .switch_label::before {\n    transform: translateX(20px);\n  }\n  \n  .switch_label_text {\n    margin-left: 8px;\n    font-size: 14px;\n    color: #333;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle_switch": `ToggleSwitch_toggle_switch__mNC1N`,
	"switch_input": `ToggleSwitch_switch_input__8BDYI`,
	"switch_label": `ToggleSwitch_switch_label__lz1t+`,
	"switch_label_text": `ToggleSwitch_switch_label_text__3KKuq`
};
export default ___CSS_LOADER_EXPORT___;
