// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AreaListView_dropdown_container__tFoSh {
  display: flex;
  align-items: first baseline;
}

.AreaListView_title__617Gl {
  margin-right: 3%;
  font-size: 13px;
  font-weight: bold;
}

.AreaListView_dropdown_main__qDkbT {
  position: relative;
  width: 120px; /* Adjust the width as needed */
  z-index: 10; /* Ensure dropdown container is on top */
}
  
.AreaListView_dropdown_header__GuU2p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #71c4c4; 
  border-left: 10px inset transparent;
  padding-top: 10%;
}
  
.AreaListView_dropdown_list__OnScH {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  z-index: 20; /* Higher z-index to ensure dropdown is above other elements */

  /* Add these styles for scrolling */
  max-height: 150px; /* Set maximum height (adjust as needed) */
  overflow-y: auto; /* Enable vertical scrolling */

}

.AreaListView_dropdown_list__OnScH li {
  padding: 8px;
  cursor: pointer;
}

.AreaListView_dropdown_list__OnScH li:hover {
  background-color: #f0f0f0;
}

.AreaListView_option__sqZ6A {
  font-size: 13px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/AreaListView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY,EAAE,+BAA+B;EAC7C,WAAW,EAAE,wCAAwC;AACvD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,gCAAgC;EAChC,mCAAmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,OAAO;EACP,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,gBAAgB;EAChB,WAAW,EAAE,8DAA8D;;EAE3E,mCAAmC;EACnC,iBAAiB,EAAE,0CAA0C;EAC7D,gBAAgB,EAAE,8BAA8B;;AAElD;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".dropdown_container {\n  display: flex;\n  align-items: first baseline;\n}\n\n.title {\n  margin-right: 3%;\n  font-size: 13px;\n  font-weight: bold;\n}\n\n.dropdown_main {\n  position: relative;\n  width: 120px; /* Adjust the width as needed */\n  z-index: 10; /* Ensure dropdown container is on top */\n}\n  \n.dropdown_header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n  font-size: 13px;\n  font-weight: bold;\n  border-bottom: 1px solid #71c4c4; \n  border-left: 10px inset transparent;\n  padding-top: 10%;\n}\n  \n.dropdown_list {\n  position: absolute;\n  width: 100%;\n  top: 100%;\n  left: 0;\n  background: white;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  margin-top: 5px;\n  padding: 0;\n  list-style: none;\n  z-index: 20; /* Higher z-index to ensure dropdown is above other elements */\n\n  /* Add these styles for scrolling */\n  max-height: 150px; /* Set maximum height (adjust as needed) */\n  overflow-y: auto; /* Enable vertical scrolling */\n\n}\n\n.dropdown_list li {\n  padding: 8px;\n  cursor: pointer;\n}\n\n.dropdown_list li:hover {\n  background-color: #f0f0f0;\n}\n\n.option {\n  font-size: 13px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown_container": `AreaListView_dropdown_container__tFoSh`,
	"title": `AreaListView_title__617Gl`,
	"dropdown_main": `AreaListView_dropdown_main__qDkbT`,
	"dropdown_header": `AreaListView_dropdown_header__GuU2p`,
	"dropdown_list": `AreaListView_dropdown_list__OnScH`,
	"option": `AreaListView_option__sqZ6A`
};
export default ___CSS_LOADER_EXPORT___;
