import React, { useEffect, useState } from 'react';
import classes from "./TemperatureGraphPanel.module.css";

import { ReactComponent as TemperatureGraphSvgAll } from '../../../../../assets/mocks/temperature-graph-all.svg';
import { ReactComponent as TemperatureGraphSvgR1 } from '../../../../../assets/mocks/temperature-graph-r1.svg';
import { ReactComponent as TemperatureGraphSvgR2 } from '../../../../../assets/mocks/temperature-graph-r2.svg';
import { ReactComponent as TemperatureGraphSvgR3 } from '../../../../../assets/mocks/temperature-graph-r3.svg';
import { ReactComponent as TemperatureGraphSvgR4 } from '../../../../../assets/mocks/temperature-graph-r4.svg';
import { ReactComponent as TemperatureGraphSvgR5 } from '../../../../../assets/mocks/temperature-graph-r5.svg';
import { ReactComponent as TemperatureGraphSvgR6 } from '../../../../../assets/mocks/temperature-graph-r6.svg';
import { ReactComponent as TemperatureGraphSvgR7 } from '../../../../../assets/mocks/temperature-graph-r7.svg';
import { ReactComponent as TemperatureGraphSvgR8 } from '../../../../../assets/mocks/temperature-graph-r8.svg';
import { ReactComponent as TemperatureGraphSvgR9 } from '../../../../../assets/mocks/temperature-graph-r9.svg';
import { ReactComponent as TemperatureGraphSvgL1 } from '../../../../../assets/mocks/temperature-graph-l1.svg';
import { ReactComponent as TemperatureGraphSvgL2 } from '../../../../../assets/mocks/temperature-graph-l2.svg';
import { ReactComponent as TemperatureGraphSvgL3 } from '../../../../../assets/mocks/temperature-graph-l3.svg';
import { ReactComponent as TemperatureGraphSvgL4 } from '../../../../../assets/mocks/temperature-graph-l4.svg';
import { ReactComponent as TemperatureGraphSvgL5 } from '../../../../../assets/mocks/temperature-graph-l5.svg';
import { ReactComponent as TemperatureGraphSvgL6 } from '../../../../../assets/mocks/temperature-graph-l6.svg';
import { ReactComponent as TemperatureGraphSvgL7 } from '../../../../../assets/mocks/temperature-graph-l7.svg';
import { ReactComponent as TemperatureGraphSvgL8 } from '../../../../../assets/mocks/temperature-graph-l8.svg';
import { ReactComponent as TemperatureGraphSvgL9 } from '../../../../../assets/mocks/temperature-graph-l9.svg';


const svgMap = {
    "All": TemperatureGraphSvgAll,
    "R1": TemperatureGraphSvgR1, "R2": TemperatureGraphSvgR2, 
    "R3": TemperatureGraphSvgR3, "R4": TemperatureGraphSvgR4, 
    "R5": TemperatureGraphSvgR5, "R6": TemperatureGraphSvgR6,
    "R7": TemperatureGraphSvgR7, "R8": TemperatureGraphSvgR8,
    "R9": TemperatureGraphSvgR9, "L1": TemperatureGraphSvgL1, 
    "L2": TemperatureGraphSvgL2, "L3": TemperatureGraphSvgL3, 
    "L4": TemperatureGraphSvgL4, "L5": TemperatureGraphSvgL5, 
    "L6": TemperatureGraphSvgL6, "L7": TemperatureGraphSvgL7, 
    "L8": TemperatureGraphSvgL8, "L9": TemperatureGraphSvgL9
}



const TemperatureGraphPanel = ({className, onClick, selectedTemperatureArea}) => {

    const [SvgComponent, setSvgComponent] = useState(null);

    useEffect(() => {
        if (selectedTemperatureArea && svgMap[selectedTemperatureArea]) {
            setSvgComponent(svgMap[selectedTemperatureArea]);
        }
    }, [selectedTemperatureArea]);
    
    return (
        <div className={`${classes.container} ${className}`} onClick={onClick}>
            {SvgComponent ? <SvgComponent className={classes.svg} /> : <p>No SVG available</p>}
        </div>          
    )
}

export default TemperatureGraphPanel;