// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding-bottom: 6%;
}

.rc-slider-dot {
  display: none;
}

.rc-slider-handle {
  background-color: white;
  border-color: #dfdfdf;
}

/* .rc-slider-handle:hover, .rc-slider-handle:focus {
  background-color: #1abc9c;
  border-color: #16a085;
} */`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/TimerSlider.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;;;GAGG","sourcesContent":[".container {\n  padding-bottom: 6%;\n}\n\n.rc-slider-dot {\n  display: none;\n}\n\n.rc-slider-handle {\n  background-color: white;\n  border-color: #dfdfdf;\n}\n\n/* .rc-slider-handle:hover, .rc-slider-handle:focus {\n  background-color: #1abc9c;\n  border-color: #16a085;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
