import React, { useEffect, useState } from 'react';
import classes from "./PressureGraphPanel.module.css";

import { ReactComponent as PressureGraphSvgAll } from '../../../../../assets/mocks/pressure-graph-all.svg';
import { ReactComponent as PressureGraphSvgR11 } from '../../../../../assets/mocks/pressure-graph-r11.svg';
import { ReactComponent as PressureGraphSvgR12 } from '../../../../../assets/mocks/pressure-graph-r12.svg';
import { ReactComponent as PressureGraphSvgR13 } from '../../../../../assets/mocks/pressure-graph-r13.svg';
import { ReactComponent as PressureGraphSvgR14 } from '../../../../../assets/mocks/pressure-graph-r14.svg';
import { ReactComponent as PressureGraphSvgR21 } from '../../../../../assets/mocks/pressure-graph-r21.svg';
import { ReactComponent as PressureGraphSvgR22 } from '../../../../../assets/mocks/pressure-graph-r22.svg';
import { ReactComponent as PressureGraphSvgR23 } from '../../../../../assets/mocks/pressure-graph-r23.svg';
import { ReactComponent as PressureGraphSvgR24 } from '../../../../../assets/mocks/pressure-graph-r24.svg';
import { ReactComponent as PressureGraphSvgR31 } from '../../../../../assets/mocks/pressure-graph-r31.svg';
import { ReactComponent as PressureGraphSvgR32 } from '../../../../../assets/mocks/pressure-graph-r32.svg';
import { ReactComponent as PressureGraphSvgR33 } from '../../../../../assets/mocks/pressure-graph-r33.svg';
import { ReactComponent as PressureGraphSvgR41 } from '../../../../../assets/mocks/pressure-graph-r41.svg';
import { ReactComponent as PressureGraphSvgR42 } from '../../../../../assets/mocks/pressure-graph-r42.svg';
import { ReactComponent as PressureGraphSvgR43 } from '../../../../../assets/mocks/pressure-graph-r43.svg';
import { ReactComponent as PressureGraphSvgR51 } from '../../../../../assets/mocks/pressure-graph-r51.svg';
import { ReactComponent as PressureGraphSvgR52 } from '../../../../../assets/mocks/pressure-graph-r52.svg';
import { ReactComponent as PressureGraphSvgR61 } from '../../../../../assets/mocks/pressure-graph-r61.svg';
import { ReactComponent as PressureGraphSvgR62 } from '../../../../../assets/mocks/pressure-graph-r62.svg';
import { ReactComponent as PressureGraphSvgL11 } from '../../../../../assets/mocks/pressure-graph-l11.svg';
import { ReactComponent as PressureGraphSvgL12 } from '../../../../../assets/mocks/pressure-graph-l12.svg';
import { ReactComponent as PressureGraphSvgL13 } from '../../../../../assets/mocks/pressure-graph-l13.svg';
import { ReactComponent as PressureGraphSvgL14 } from '../../../../../assets/mocks/pressure-graph-l14.svg';
import { ReactComponent as PressureGraphSvgL21 } from '../../../../../assets/mocks/pressure-graph-l21.svg';
import { ReactComponent as PressureGraphSvgL22 } from '../../../../../assets/mocks/pressure-graph-l22.svg';
import { ReactComponent as PressureGraphSvgL23 } from '../../../../../assets/mocks/pressure-graph-l23.svg';
import { ReactComponent as PressureGraphSvgL24 } from '../../../../../assets/mocks/pressure-graph-l24.svg';
import { ReactComponent as PressureGraphSvgL31 } from '../../../../../assets/mocks/pressure-graph-l31.svg';
import { ReactComponent as PressureGraphSvgL32 } from '../../../../../assets/mocks/pressure-graph-l32.svg';
import { ReactComponent as PressureGraphSvgL33 } from '../../../../../assets/mocks/pressure-graph-l33.svg';
import { ReactComponent as PressureGraphSvgL41 } from '../../../../../assets/mocks/pressure-graph-l41.svg';
import { ReactComponent as PressureGraphSvgL42 } from '../../../../../assets/mocks/pressure-graph-l42.svg';
import { ReactComponent as PressureGraphSvgL43 } from '../../../../../assets/mocks/pressure-graph-l43.svg';
import { ReactComponent as PressureGraphSvgL51 } from '../../../../../assets/mocks/pressure-graph-l51.svg';
import { ReactComponent as PressureGraphSvgL52 } from '../../../../../assets/mocks/pressure-graph-l52.svg';
import { ReactComponent as PressureGraphSvgL61 } from '../../../../../assets/mocks/pressure-graph-l61.svg';
import { ReactComponent as PressureGraphSvgL62 } from '../../../../../assets/mocks/pressure-graph-l62.svg';



const svgMap = {
    "All": PressureGraphSvgAll,
    "R1.1": PressureGraphSvgR11, "R1.2": PressureGraphSvgR12, 
    "R1.3": PressureGraphSvgR13, "R1.4": PressureGraphSvgR14, 
    "R2.1": PressureGraphSvgR21, "R2.2": PressureGraphSvgR22,
    "R2.3": PressureGraphSvgR23, "R2.4": PressureGraphSvgR24,
    "R3.1": PressureGraphSvgR31, "R3.2": PressureGraphSvgR32, 
    "R3.3": PressureGraphSvgR33, "R4.1": PressureGraphSvgR41, 
    "R4.2": PressureGraphSvgR42, "R4.3": PressureGraphSvgR43,
    "R5.1": PressureGraphSvgR51, "R5.2": PressureGraphSvgR52,
    "R6.1": PressureGraphSvgR61, "R6.2": PressureGraphSvgR62,
    "L1.1": PressureGraphSvgL11, "L1.2": PressureGraphSvgL12,
    "L1.3": PressureGraphSvgL13, "L1.4": PressureGraphSvgL14,
    "L2.1": PressureGraphSvgL21, "L2.2": PressureGraphSvgL22,
    "L2.3": PressureGraphSvgL23, "L2.4": PressureGraphSvgL24,
    "L3.1": PressureGraphSvgL31, "L3.2": PressureGraphSvgL32,
    "L3.3": PressureGraphSvgL33, "L4.1": PressureGraphSvgL41, 
    "L4.2": PressureGraphSvgL42, "L4.3": PressureGraphSvgL43,
    "L5.1": PressureGraphSvgL51, "L5.2": PressureGraphSvgL52,
    "L6.1": PressureGraphSvgL61, "L6.2": PressureGraphSvgL62,    
};

const PressureGraphPanel = ({ className, onClick, selectedPressureArea }) => {
    
    const [SvgComponent, setSvgComponent] = useState(null);

    useEffect(() => {
        if (selectedPressureArea && svgMap[selectedPressureArea]) {
            setSvgComponent(svgMap[selectedPressureArea]);
        }
    }, [selectedPressureArea]);

    return (
        <div className={`${classes.container} ${className}`} onClick={onClick}>
            {SvgComponent ? <SvgComponent className={classes.svg} /> : <p>No SVG available</p>}
        </div>
    );
};

export default PressureGraphPanel;