// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bar_bar__6Gt0m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Transparent background */
    border-radius: 15px; /* Rounded corners */
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 1100px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 3%;
}

.Bar_bar_logo__wrFnm {
    height: 35pt;
}

.Bar_support_button__-MC81 {
    background-color: #161a28;
    color: white;
    border: none;
    padding: 8px 30px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
}

.Bar_support_button__-MC81:hover {
    background-color: #494949;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/login/Bar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,0CAA0C,EAAE,2BAA2B;IACvE,mBAAmB,EAAE,oBAAoB;IACzC,eAAe;IACf,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,iBAAiB;IACjB,yCAAyC;IACzC,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".bar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 20px;\n    background-color: rgba(255, 255, 255, 0.8); /* Transparent background */\n    border-radius: 15px; /* Rounded corners */\n    position: fixed;\n    left: 50%;\n    transform: translateX(-50%);\n    width: 90%;\n    max-width: 1100px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    margin-top: 3%;\n}\n\n.bar_logo {\n    height: 35pt;\n}\n\n.support_button {\n    background-color: #161a28;\n    color: white;\n    border: none;\n    padding: 8px 30px;\n    border-radius: 7px;\n    cursor: pointer;\n    font-size: 12px;\n    font-family: 'Poppins', sans-serif;\n}\n\n.support_button:hover {\n    background-color: #494949;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `Bar_bar__6Gt0m`,
	"bar_logo": `Bar_bar_logo__wrFnm`,
	"support_button": `Bar_support_button__-MC81`
};
export default ___CSS_LOADER_EXPORT___;
