import React, {useState} from 'react';
import './Pressure.css';
import PressurePanel from './PressurePanel';
import PressureGraphPanel from './PressureGraphPanel';


const Pressure = () => {


  const [selectedDiv, setSelectedDiv] = useState(1);
  const [selectedArea, setSelectedArea] = useState("All");

  const handleClick = (divId) => {
    setSelectedDiv(divId);
  };

  const handleSelectedArea = (selectedArea) => {
    setSelectedArea(selectedArea);
  }

  const marks = {
    0: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week4</div>
        </div>
      )
    },
    1: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },
    2: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week5</div>
        </div>
      )
    },
    3: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    4: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week6</div>
        </div>
      )
    },
    5: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },
    6: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week7</div>
        </div>
      )
    },
    7: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    8: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week8</div>
        </div>
      )
    },
    9: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    10: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>NOW</div>
        </div>
      ) 
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <PressurePanel
        title="Feet Map"
        marks={marks}
        className={`selectable-div ${selectedDiv === 1 ? 'selected' : ''}`}
        onClick={() => handleClick(1)}
        onSelectArea={handleSelectedArea}
      />
      <PressureGraphPanel
        className={`selectable-div ${selectedDiv === 2 ? 'selected' : ''}`}
        onClick={() => handleClick(2)}
        selectedPressureArea={selectedArea}
      />
    </div>
  );

};

export default Pressure;