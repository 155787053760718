// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_container__-0Q1b {
  display: flex;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  height: 58rem;
  width: 90rem;
  margin: 20px 150px 150px 100px; /* Increase margin around the container */
}
  
.Home_left__WUbPp {
  margin-right: 3%;
  background-color: white; /* Just for visualization */
  display: flex;
  flex-direction: column; /* Align items vertically */
  padding: 10px;
}
  
.Home_right__tzHCQ {
  flex: 1 1; /* Flex-grow: 1, Flex-shrink: 1, Flex-basis: 0 (remaining space) */
  background-color: #f8f9fc; /* Just for visualization */
  display: flex;
  flex-direction: column; /* Align items vertically */
  padding: 10px;
  padding-left: 5%;
  height: 105%;
}`, "",{"version":3,"sources":["webpack://./src/components/home/Home.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB,EAAE,mFAAmF;EAC3G,aAAa;EACb,YAAY;EACZ,8BAA8B,EAAE,yCAAyC;AAC3E;;AAEA;EACE,gBAAgB;EAChB,uBAAuB,EAAE,2BAA2B;EACpD,aAAa;EACb,sBAAsB,EAAE,2BAA2B;EACnD,aAAa;AACf;;AAEA;EACE,SAAO,EAAE,kEAAkE;EAC3E,yBAAyB,EAAE,2BAA2B;EACtD,aAAa;EACb,sBAAsB,EAAE,2BAA2B;EACnD,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */\n  height: 58rem;\n  width: 90rem;\n  margin: 20px 150px 150px 100px; /* Increase margin around the container */\n}\n  \n.left {\n  margin-right: 3%;\n  background-color: white; /* Just for visualization */\n  display: flex;\n  flex-direction: column; /* Align items vertically */\n  padding: 10px;\n}\n  \n.right {\n  flex: 1; /* Flex-grow: 1, Flex-shrink: 1, Flex-basis: 0 (remaining space) */\n  background-color: #f8f9fc; /* Just for visualization */\n  display: flex;\n  flex-direction: column; /* Align items vertically */\n  padding: 10px;\n  padding-left: 5%;\n  height: 105%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Home_container__-0Q1b`,
	"left": `Home_left__WUbPp`,
	"right": `Home_right__tzHCQ`
};
export default ___CSS_LOADER_EXPORT___;
