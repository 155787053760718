// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemperatureGraphPanel_container__LEvvV {
  background-color: white;
  width: 30rem;
  height: 85%;
  padding: 1rem;
  border-radius: 3%;
  display: grid;
  place-items: center;
}

.TemperatureGraphPanel_svg__U1f7M {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/temperature/TemperatureGraphPanel.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  background-color: white;\n  width: 30rem;\n  height: 85%;\n  padding: 1rem;\n  border-radius: 3%;\n  display: grid;\n  place-items: center;\n}\n\n.svg {\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TemperatureGraphPanel_container__LEvvV`,
	"svg": `TemperatureGraphPanel_svg__U1f7M`
};
export default ___CSS_LOADER_EXPORT___;
