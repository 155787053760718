import React from 'react';
import classes from "./StepsGraphPanel.module.css";

import { ReactComponent as StepsGraphSvg } from '../../../../../assets/mocks/steps-graph.svg';


const StepsGraphPanel = ({className, onClick}) => {
    return (
        <div className={`${classes.container} ${className}`} onClick={onClick}>
            <StepsGraphSvg className={classes.svg} />
      </div>
  
    )
}

export default StepsGraphPanel