// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__98vDa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
}


.Header_header_list_view__h7EJm {
    padding-right: 5%;
}

.Header_header_label_1__5gFmV {
    color: #adb5bb;
}

.Header_header_label_2__DKmsq {
    font-size: 15px;
    font-weight: bold;
    padding-top: 2%;
}

.Header_header_label_3__mQNNX {
    padding-top: 3%;
    font-weight: 600;
    font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;AAClB;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 2%;\n}\n\n\n.header_list_view {\n    padding-right: 5%;\n}\n\n.header_label_1 {\n    color: #adb5bb;\n}\n\n.header_label_2 {\n    font-size: 15px;\n    font-weight: bold;\n    padding-top: 2%;\n}\n\n.header_label_3 {\n    padding-top: 3%;\n    font-weight: 600;\n    font-size: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__98vDa`,
	"header_list_view": `Header_header_list_view__h7EJm`,
	"header_label_1": `Header_header_label_1__5gFmV`,
	"header_label_2": `Header_header_label_2__DKmsq`,
	"header_label_3": `Header_header_label_3__mQNNX`
};
export default ___CSS_LOADER_EXPORT___;
