// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PressureGraphPanel_container__3bZif {
    background-color: white;
    width: 29rem;
    height: 85%;
    padding: 1.0rem;
    border-radius: 3%;
    display: grid;
    place-items: center;
}

.PressureGraphPanel_svg__u5zsE {
    width: 100%; 
    height: auto;
    max-width: 100%;
    max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/pressure/PressureGraphPanel.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".container {\n    background-color: white;\n    width: 29rem;\n    height: 85%;\n    padding: 1.0rem;\n    border-radius: 3%;\n    display: grid;\n    place-items: center;\n}\n\n.svg {\n    width: 100%; \n    height: auto;\n    max-width: 100%;\n    max-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PressureGraphPanel_container__3bZif`,
	"svg": `PressureGraphPanel_svg__u5zsE`
};
export default ___CSS_LOADER_EXPORT___;
