// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportButton_support_button__7RR7E {
    background-color: #FFFFFF;
    color: #313840;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
  }
  
  .SupportButton_support_button__7RR7E:hover {
    background-color: #f8f9fc;
  }
    
  .SupportButton_title_text__N9FBc {
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/components/home/left-panel/SupportButton.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,sCAAsC;IACtC,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".support_button {\n    background-color: #FFFFFF;\n    color: #313840;\n    font-weight: bold;\n    border: none;\n    border-radius: 10px;\n    padding: 8px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    text-transform: uppercase;\n  }\n  \n  .support_button:hover {\n    background-color: #f8f9fc;\n  }\n    \n  .title_text {\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support_button": `SupportButton_support_button__7RR7E`,
	"title_text": `SupportButton_title_text__N9FBc`
};
export default ___CSS_LOADER_EXPORT___;
