// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PressurePanel_container__nCMoj {
    background-color: white;
    width: 29rem;
    height: 85%;
    padding: 1.0rem;
    border-radius: 3%;
}

.PressurePanel_edge_items__jGjHQ {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PressurePanel_label_1__ufhjC {
    font-weight: 550;
    font-size: 20px;
}

.PressurePanel_svg__pkFq6 {
    width: 60%;
    height: 60%;
    display: block;
    margin: auto;
    margin-top: 2.5rem;
}

.PressurePanel_side_labels__gWlby {
    color: #00A3AD;
    font-weight: 600;
    font-size: 12px;
}

.PressurePanel_week_slider__O0MSl {
    width: 80%; 
    margin: 30px auto
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/pressure/PressurePanel.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV;AACJ","sourcesContent":[".container {\n    background-color: white;\n    width: 29rem;\n    height: 85%;\n    padding: 1.0rem;\n    border-radius: 3%;\n}\n\n.edge_items {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.label_1 {\n    font-weight: 550;\n    font-size: 20px;\n}\n\n.svg {\n    width: 60%;\n    height: 60%;\n    display: block;\n    margin: auto;\n    margin-top: 2.5rem;\n}\n\n.side_labels {\n    color: #00A3AD;\n    font-weight: 600;\n    font-size: 12px;\n}\n\n.week_slider {\n    width: 80%; \n    margin: 30px auto\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PressurePanel_container__nCMoj`,
	"edge_items": `PressurePanel_edge_items__jGjHQ`,
	"label_1": `PressurePanel_label_1__ufhjC`,
	"svg": `PressurePanel_svg__pkFq6`,
	"side_labels": `PressurePanel_side_labels__gWlby`,
	"week_slider": `PressurePanel_week_slider__O0MSl`
};
export default ___CSS_LOADER_EXPORT___;
