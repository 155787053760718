import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import classes from './Navbar.module.css';

const Navbar = ({ onLogout }) => {
  const [activeLink, setActiveLink] = useState('/overview'); // Set default to Overview
  const location = useLocation();

  // Function to get the class for a link
  const getLinkClass = (link) => {
    return classNames(classes.nav_link, {
      [classes.active]: link === activeLink
    });
  };

  useEffect(() => {
    // Update the active link based on current location
    if (location.pathname === '/') {
      setActiveLink('/overview'); // Default to overview
    } else {
      setActiveLink(location.pathname);
    }
  }, [location]);

  return (
    <nav className={classes.nav_bar}>
      <Link className={getLinkClass('/overview')} to="/overview" onClick={() => setActiveLink('/overview')}>
        Overview
      </Link>
      <Link className={getLinkClass('/pressure')} to="/pressure" onClick={() => setActiveLink('/pressure')}>
        Feet Pressure
      </Link>
      <Link className={getLinkClass('/temperature')} to="/temperature" onClick={() => setActiveLink('/temperature')}>
        Feet Temperature
      </Link>
      <Link className={getLinkClass('/skin-hydration')} to="/skin-hydration" onClick={() => setActiveLink('/skin-hydration')}>
        Skin Hydration
      </Link>
      <Link className={getLinkClass('/steps')} to="/steps" onClick={() => setActiveLink('/steps')}>
        Step Count
      </Link>
    </nav>
  );
};

export default Navbar;