// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.Navbar_nav_bar__-onaQ {
  padding: 30px 0px 10px 0px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  margin-right: 100px;
}

.Navbar_nav_link__95V3X {
  color: #828282;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 46.4px;
  font-size: 16px;
  transition: color 0.3s ease;
  text-decoration: none;  
  border-bottom: none;    
}

.Navbar_nav_link__95V3X:hover {
  color: #01677f;
  text-decoration: none;  
  border-bottom: none;    
}

.Navbar_nav_link__95V3X.Navbar_active__ivlbO {
  color: #00a3ad;
  border-bottom: 3px solid #00a3ad; 
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,oBAAoB;EACpB,eAAe;EACf,2BAA2B;EAC3B,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,qBAAqB;AACvB","sourcesContent":["* {\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\n.nav_bar {\n  padding: 30px 0px 10px 0px;\n  border-bottom: 1px solid #e0e0e0;\n  position: relative;\n  margin-right: 100px;\n}\n\n.nav_link {\n  color: #828282;\n  background: none;\n  border: none;\n  cursor: pointer;\n  padding: 10px 46.4px;\n  font-size: 16px;\n  transition: color 0.3s ease;\n  text-decoration: none;  \n  border-bottom: none;    \n}\n\n.nav_link:hover {\n  color: #01677f;\n  text-decoration: none;  \n  border-bottom: none;    \n}\n\n.nav_link.active {\n  color: #00a3ad;\n  border-bottom: 3px solid #00a3ad; \n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav_bar": `Navbar_nav_bar__-onaQ`,
	"nav_link": `Navbar_nav_link__95V3X`,
	"active": `Navbar_active__ivlbO`
};
export default ___CSS_LOADER_EXPORT___;
