import React, { useState } from "react";
import classes from "./SkinHydrationPanel.module.css";
import ButtonGroup from "../ButtonGroup";
import { ReactComponent as LeftInsoleSvg } from '../../../../../assets/svg/hydration-left-insole.svg';
import { ReactComponent as RightInsoleSvg } from '../../../../../assets/svg/hydration-right-insole.svg';
import TimerSlider from "../TimerSlider";
import AreaListView from "../AreaListView";

const SkinHydrationPanel = ({ title, marks, className, onClick, onSelectArea }) => {

    const [selectedInsole, setSelectedInsole] = useState(null);

    const handleInsoleClick = (insole) => {
        
        setSelectedInsole(insole);

        // Reset styles for both SVG elements
        const insoleL3 = document.getElementById('insole_L_layer_3');
        insoleL3.style.stroke = '#05A3AC';
        insoleL3.style.strokeWidth = '2px';
        insoleL3.style.strokeOpacity = '0.4';

        const insoleL1White = document.getElementById('insole_L_layer_1_white');
        insoleL1White.style.visibility = 'visible';
        
        const insoleR3 = document.getElementById('insole_R_layer_3');
        insoleR3.style.stroke = '#05A3AC';
        insoleR3.style.strokeWidth = '2px';
        insoleR3.style.strokeOpacity = '0.4';

        const insoleR1White = document.getElementById('insole_R_layer_1_white');
        insoleR1White.style.visibility = 'visible';


        // Apply style to the clicked insole
        if (insole === 'left') {
            insoleL3.style.stroke = '#FFC530';
            insoleL3.style.strokeWidth = '5px';
            insoleL3.style.strokeOpacity = '1';
            insoleL1White.style.visibility = 'hidden';
            document.getElementById('insole_L_layer_2').style.marginTop = '3px;'
            
            
        } else if (insole === 'right') {
            insoleR3.style.stroke = '#FFC530';
            insoleR3.style.strokeWidth = '5px';
            insoleR3.style.strokeOpacity = '1';
            insoleR1White.style.visibility = 'hidden';
        }

        insole === "left" ? onSelectArea("L") : onSelectArea("R");

    };

    const handleMouseOver = (ev) => {
        const isLeftInsole = ev.target.id.includes("insole_L");
        const targetElement = isLeftInsole ? document.getElementById('insole_L_layer_3') : document.getElementById('insole_R_layer_3');

        // If the insole is not the one selected, apply hover effect
        if ((isLeftInsole && selectedInsole !== 'left') || (!isLeftInsole && selectedInsole !== 'right')) {
            targetElement.style.stroke = '#FFC530'; 
            targetElement.style.strokeOpacity = '1';
        }
    };

    const handleMouseOut = (ev) => {
        const isLeftInsole = ev.target.id.includes("insole_L");
        const targetElement = isLeftInsole ? document.getElementById('insole_L_layer_3') : document.getElementById('insole_R_layer_3');

        // Only reset if the insole is not the one that was clicked
        if ((isLeftInsole && selectedInsole !== 'left') || (!isLeftInsole && selectedInsole !== 'right')) {
            targetElement.style.stroke = '';
            targetElement.style.strokeWidth = '';
            targetElement.style.strokeOpacity = '';
        }
    };

    const onChangeCompleteHandler = (value) => {
        console.log(`On change event triggered: ${value}`);
    };

    const options = ["None"];

    return (
        <React.Fragment>
           <div className={`${classes.container} ${className}`} onClick={onClick}>
                <div className={classes.edge_items}>
                    <label className={classes.label_1}>{title}</label>
                    <ButtonGroup />
                </div>
                <div className={classes.listview}>
                    <AreaListView title="Pressure area" options={options} />
                </div>
                <div className={classes.svg_container}>
                    <div className={classes.svg_item} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                        <LeftInsoleSvg className={classes.svg} onClick={() => handleInsoleClick('left')} />
                    </div>
                    <div className={classes.svg_item} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                        <RightInsoleSvg className={classes.svg} onClick={() => handleInsoleClick('right')} />
                    </div>
                </div>
                <div className={classes.edge_items}>
                    <label className={classes.side_labels}>Left</label>
                    <label className={classes.side_labels}>Right</label>
                </div>
                <div className={classes.week_slider}>
                    <TimerSlider marks={marks} onSlideChange={onChangeCompleteHandler} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default SkinHydrationPanel;