import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SelectArrows } from '../../../../assets/svg/select_arrows.svg';
import classes from './AreaListView.module.css';

const AreaListView = ({ title, options, onListViewOptionSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null);
  const selectedOptionRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    onListViewOptionSelect(option);
    setIsOpen(false);
  };

  // Scroll to the selected option when the dropdown is opened
  useEffect(() => {
    if (isOpen && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isOpen]);

  // Handle clicks outside of the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.dropdown_container}>
        <span className={classes.title}>{title}</span>
        <div>
            <div className={classes.dropdown_main} ref={dropdownRef}>
            <div className={classes.dropdown_header} onClick={toggleDropdown}>
                <span>{selectedOption}</span>
                <div>
                    <SelectArrows />
                </div>
            </div>
            {isOpen && (
                <ul className={classes.dropdown_list}>
                {options.map((option, index) => (
                    <li 
                        key={index}
                        onClick={() => selectOption(option)}
                        ref={option === selectedOption ? selectedOptionRef : null} // Set ref for the selected option
                    >
                        <span className={classes.option}>{option}</span>
                    </li>
                ))}
                </ul>
            )}
            </div>
        </div>
    </div>
  );
};

export default AreaListView;