import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import classes from './Home.module.css';
import Navbar from './navbar/Navbar';
import Panel from './left-panel/Panel';
import Header from './header/Header';
import Overview from './navbar/pages/overview/Overview';
import Pressure from './navbar/pages/pressure/Pressure';
import Temperature from './navbar/pages/temperature/Temperature';
import SkinHydration from './navbar/pages/skin-hydration/SkinHydration';
import Steps from './navbar/pages/steps/Steps';

function Home({ onLogout }) {
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <Panel />
      </div>
      <div className={classes.right}>
        <Header />
        <Navbar onLogout={onLogout} />

        {/* Routes for the pages */}
        <Routes>
          <Route path="/overview" element={<Overview />} />
          <Route path="/pressure" element={<Pressure />} />
          <Route path="/temperature" element={<Temperature />} />
          <Route path="/skin-hydration" element={<SkinHydration />} />
          <Route path="/steps" element={<Steps />} />
          <Route path="/" element={<Navigate to="/overview" />} /> {/* Redirect to Overview by default */}
        </Routes>
      </div>
    </div>
  );
}

export default Home;