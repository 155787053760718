import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({ password: '', id: '', isAuthenticated: false });

  const login = (password, id) => {
    setAuthData({ password, id, isAuthenticated: true });
  };

  const logout = () => {
    setAuthData({ password: '', id: '', isAuthenticated: false });
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
