// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pages_page__mlM05 {
    margin-top: 4%;
}

.Pages_panel__a-zrK {
    margin-top: 4%;
}`, "",{"version":3,"sources":["webpack://./src/components/home/navbar/pages/Pages.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".page {\n    margin-top: 4%;\n}\n\n.panel {\n    margin-top: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Pages_page__mlM05`,
	"panel": `Pages_panel__a-zrK`
};
export default ___CSS_LOADER_EXPORT___;
