import React, { useState, useEffect, useContext } from 'react';
import ToggleSwitch from './ToggleSwitch';
import classes from "./LoginUser.module.css";
import Papa from 'papaparse';
import { AuthContext } from '../../AuthContext';

const LoginUser = () => {
  const { login } = useContext(AuthContext); // Get the login function from context
  const [password, setPassword] = useState('');
  const [passwordMap, setPasswordMap] = useState(new Map());

  useEffect(() => {
    const loadPasswords = () => {
      const filePath = '/passwords.csv';
      fetch(filePath)
        .then(response => response.text())
        .then(csvData => {
          const parsedData = Papa.parse(csvData, { header: false }).data;
          const map = new Map();
          parsedData.forEach(([pwd, id]) => {
            map.set(pwd, id); // Map each password to its corresponding ID
          });
          setPasswordMap(map);
        })
        .catch(error => {
          console.error('Error loading passwords:', error);
        });
    };

    loadPasswords();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordMap.has(password)) {
      const id = passwordMap.get(password);
      login(password, id); // Store password and ID in the context
    } else {
      alert('Invalid password');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.login_form_container}>
      <label className={classes.welcome_text}>Welcome Back!</label>
      <label className={classes.pass_inst_text}>Enter your email and password to sign in</label>
      <label className={classes.label}>Email</label>
      <input className={classes.input} placeholder="Your work email" />
      <label className={classes.label}>Password</label>
      <input
        className={classes.input}
        placeholder="Your password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <ToggleSwitch label="Remember me" />
      <button className={classes.sign_in_button} type="submit">Sign in</button>
      <div className={classes.account}>
        <label className={classes.label}>Don't have an account? </label>
        <label className={classes.green_label}>Register</label>
      </div>
    </form>
  );
};

export default LoginUser;